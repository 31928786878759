import { render } from "./CategoryElevenFileUploadComponent.vue?vue&type=template&id=7b6a0f0e&scoped=true"
import script from "./CategoryElevenFileUploadComponent.vue?vue&type=script&lang=ts"
export * from "./CategoryElevenFileUploadComponent.vue?vue&type=script&lang=ts"

import "./CategoryElevenFileUploadComponent.vue?vue&type=style&index=0&id=7b6a0f0e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-7b6a0f0e"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QForm,QIcon,QTooltip,QSelect,QItem,QItemSection,QFile,QBtn});

import { defineComponent } from 'vue';
import CategoryElevenComponent from '@/components/CategoryEleven/CategoryElevenComponent.vue';
export default defineComponent({
    name: 'CategoryElevenDialog',
    props: {
        propSelected: Array
    },
    components: { CategoryElevenComponent },
    data: function () {
        return {
            show: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});

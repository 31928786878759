import { defineComponent } from 'vue';
import CategoryElevenRecords from '@/views/CategoryEleven/CategoryElevenRecords.vue';
export default defineComponent({
    name: 'CategoryElevenLayout',
    components: { CategoryElevenRecords },
    data() {
        return {
            loading: false,
            tab: 'Cat. 11 - Use of Sold Products Records'
        };
    }
});

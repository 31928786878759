import { defineComponent } from 'vue';
import { deleteCategoryElevenRecord, getCategoryElevenRecords } from '@/api/dataentry-api-generated';
import CategoryElevenDialog from '@/components/CategoryEleven/CategoryElevenDialog.vue';
import CategoryElevenFileUploadDialog from '@/components/CategoryEleven/CategoryElevenFileUploadDialog.vue';
import CategoryElevenDuplicateDialog from '@/components/CategoryEleven/CategoryElevenDuplicateDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'CategoryElevenRecords',
    components: { CategoryElevenDialog, CategoryElevenFileUploadDialog, CategoryElevenDuplicateDialog, DeleteDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'categoryElevenSupplierLabel',
                    name: 'supplier',
                    label: 'Supplier',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'productCategory',
                    name: 'productCategory',
                    label: 'Product Category',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'mainMaterials',
                    name: 'mainMaterials',
                    label: 'Main Material(s)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfUnitsSold9MonthsActuals',
                    name: 'noOfUnitsSold9MonthsActuals',
                    label: 'Units Sold - 9 months',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfUnitsSold9MonthsActuals3MonthsAccruals',
                    name: 'noOfUnitsSold9MonthsActuals3MonthsAccruals',
                    label: 'Units Sold - 12  months',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'energyRating',
                    name: 'energyRating',
                    label: 'Energy Rating / W',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'energyRatingUnit',
                    name: 'energyRatingUnit',
                    label: 'Unit',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'lifespanOfProduct',
                    name: 'lifespanOfProduct',
                    label: 'Lifespan of Product',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'annualEnergyUse',
                    name: 'annualEnergyUse',
                    label: 'Annual Energy Use',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'annualEnergyUseUnit',
                    name: 'annualEnergyUseUnit',
                    label: 'Energy Use Unit',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                }
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'supplier',
                descending: false,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Category 11 Records' : 'Category 11 Record';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getCategoryElevenRecords({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Cat. 11 - Use of Sold Products', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteCategoryElevenRecord(i.uuid);
                    this.$log.addMessage('Successfully deleted Category 11 record');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
